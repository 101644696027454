<template>
    <div class="menu-wrapper" :class="{ 'layout-sidebar-active' : sidebarActive}"
        @click="onMenuClick" @mouseover="onSidebarMouseOver" @mouseleave="onSidebarMouseLeave">
        <div class="sidebar-logo">
            <router-link to="/">
                <img src="layout/images/logo-freya-single.svg" alt="freya-layout">
            </router-link>
            <a class="sidebar-pin" @click="onToggleMenu($event)">
                <span class="pin"></span>
            </a>
        </div>

        <div class="layout-menu-container">
            <AppSubmenu class="layout-menu" :items="menu" :layoutMode="layoutMode" :parentMenuItemActive="true" :menuActive="menuActive" :mobileMenuActive="mobileMenuActive" :root="true" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />
        </div>
    </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";
export default {
    name: "AppMenu",
    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "toggle-menu", "sidebar-mouse-over", "sidebar-mouse-leave"],
    props: {
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean
    },
    data() {
        return {
            menu: [
                {label: "Home", icon: "pi pi-home", to: "/"},
                {
                    label: "Rマーケット", icon: "pi pi-globe",
                    items: [
                        {label: "銘柄ニュース", icon: "pi pi-map", to: "/mnews"},
                        {label: "主要指標", icon: "pi pi-check-square", to: "/mindex"},
                        {label: "注目銘柄", icon: "pi pi-building", to: "/mmeigara"},
                        {label: "株テーマ", icon: "pi pi-sitemap", to: "/mtheme"},
                    ],
                },
                {
                    label: "Rインサイト", icon: "pi pi-compass",
                    items: [
                        {label: "株エレメント", icon: "pi pi-book", to: "/ielement"},
                        {label: "関連銘柄検索", icon: "pi pi-link", to: "/blocks"},
                        {label: "銘柄共通要素", icon: "pi pi-box", to: "/"},
                        {label: "株価トレンド", icon: "pi pi-eye", to: "/"},
                    ]
                },
                {
                    label: "About", icon: "pi pi-question-circle",
                    items: [
                        {label: "Documentation", icon: "pi pi-info-circle", to: "/documentation"},
                    ],
                },
            ]
        }
    },
    methods: {
        onSidebarMouseOver() {
            if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
                this.$emit("sidebar-mouse-over");
            }
        },
        onSidebarMouseLeave() {
            if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
                setTimeout(() => {
                    this.$emit("sidebar-mouse-leave");
                }, 250);
            }
        },
        onToggleMenu(event) {
            this.$emit("toggle-menu", event);
        },
        onMenuClick(event) {
            this.$emit("menu-click", event);
        },
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event);
        },
		onRootMenuItemClick(event) {
			this.$emit("root-menuitem-click", event);
		}
    },
    components: { AppSubmenu },
};
</script>