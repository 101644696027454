<template>
    <div class="layout-footer">
        <div class="grid">
            <div class="col-12 lg:col-4">
                <div class="grid">
                    <div class="col-6">
                        <span class="footer-menutitle">Rマーケット</span>
                        <ul>
                            <li><router-link to="/"><i class="pi pi-map"/> 銘柄ニュース</router-link></li>
                            <li><router-link to="/mindex"><i class="pi pi-check-square"/> 主要指標</router-link></li>
                            <li><router-link to="/mmeigara"><i class="pi pi-building"/> 注目銘柄</router-link></li>
                            <li><router-link to="/mtheme"><i class="pi pi-sitemap"/> 株テーマ</router-link></li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <span class="footer-menutitle">Rインサイト</span>
                        <ul>
                            <li><router-link to="/ielement"><i class="pi pi-book"/> 株エレメント</router-link></li>
                            <li><router-link to="/"><i class="pi pi-link"/> 関連銘柄検索</router-link></li>
                            <li><router-link to="/"><i class="pi pi-box"/> 銘柄共通要素</router-link></li>
                            <li><router-link to="/"><i class="pi pi-eye"/> 株価トレンド</router-link></li>
                        </ul>
                    </div>
                </div>

            </div>
            <div class="col-12 md:col-6 lg:col-3">
                <span class="footer-menutitle">CONTACT US</span>
                <ul>
                    <li><i class="pi pi-envelope"></i> webmaster@tradersr.com</li>
                    <li><i class="pi pi-twitter"></i> twitter</li>
                    <li><i class="pi pi-facebook"></i> facebook</li>
                    <li><i class="pi pi-github"></i> github</li>
                    <!-- <li>Mcallister St San Francisco,</li>
                    <li>California(CA), 94102</li> -->
                </ul>
            </div>
            <div class="col-12 md:col-6 lg:col-5">
                <span class="footer-menutitle">NEWSLETTER</span>
                <span class="footer-subtitle">Join our newsletter to get notification about the new features.</span>
                    <div class="newsletter-input">
                        <InputText type="text" placeholder="email address" />
                        <Button type="button" label="Join" class="p-button-secondary"></Button>
                    </div>
            </div>
            <div class="col-12">
                <div class="footer-bottom">
                    <h4>tradersR</h4>
                    <h6>Copyright Ⓒ tradersR.com</h6>
                </div>
            </div>
        </div>
    </div>

    <ScrollTop />
</template>

<script>
export default {
    name: "AppFooter"
};
</script>