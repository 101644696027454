import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () => import('./components/Dashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }],
        },
    },
    {
        path: '/formlayout',
        name: 'formlayout',
        exact: true,
        component: () => import('./components/FormLayoutDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Form Layout' }],
        },
    },
    {
        path: '/mnews',
        name: 'mnews',
        exact: true,
        component: () => import('./pages/MeigaraNews.vue'),
        meta: {
            breadcrumb: [{ parent: 'Market', label: 'News' }],
        },
    },
    {
        path: '/mnews/:code',
        name: 'mnews_code',
        exact: true,
        component: () => import('./pages/MeigaraNews.vue'),
        meta: {
            breadcrumb: [{ parent: 'Market', label: 'News' }],
        },
    },
    {
        path: '/mindex',
        name: 'mindex',
        exact: true,
        component: () => import('./pages/MarketIndex.vue'),
        meta: {
            breadcrumb: [{ parent: 'Market', label: 'Index' }],
        },
    },
    {
        path: '/mtheme',
        name: 'mtheme',
        exact: true,
        component: () => import('./pages/MarketTheme.vue'),
        meta: {
            breadcrumb: [{ parent: 'Market', label: 'Theme' }],
        },
    },
    {
        path: '/mmeigara',
        name: 'mmeigara',
        exact: true,
        component: () => import('./pages/MarketMeigara.vue'),
        meta: {
            breadcrumb: [{ parent: 'Market', label: 'Meigara' }],
        },
    },
    {
        path: '/ielement',
        name: 'ielement',
        exact: true,
        component: () => import('./pages/InsightElement.vue'),
        meta: {
            breadcrumb: [{ parent: 'Insight', label: 'Element' }],
        },
    },
    {
        path: '/invalidstate',
        name: 'invalidstate',
        exact: true,
        component: () => import('./components/InvalidStateDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Invalid State' }],
        },
    },
    {
        path: '/button',
        name: 'button',
        exact: true,
        component: () => import('./components/ButtonDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Button' }],
        },
    },
    {
        path: '/documentation',
        name: 'documentation',
        exact: true,
        component: () => import('./components/Documentation.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Documentation' }],
        },
    },
    {
        path: '/blocks',
        name: 'blocks',
        exact: true,
        component: () => import('./components/BlocksDemo.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;